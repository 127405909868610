<template>
  <v-card>

    <v-data-table :headers="headers" :items="organizationWithIndex">
      <template v-slot:top>
        <v-toolbar flat>

          <v-toolbar-title class="info--text font-weight-bold text--darken-1 justify-center">
            <span>Organizations</span>

          </v-toolbar-title>

          <v-spacer></v-spacer>

        </v-toolbar>
      </template>
    </v-data-table>

  </v-card>
</template>

<script>
import FacilitiesService from '../../services/facilities.service'
export default {

  name: "FacilityOrganizations",
  props: [
    'facility'
  ],

  data() {
    return {
      organizations: [],
      headers: [
        {
          text: "#",
          align: "start",
          filterable: true,
          value: "index",
        }, 
        {
          text: "Organization",
          align: "start",
          filterable: true,
          value: "legal_name",
        },
      ]
    }
  },

  created() {
    this.$nextTick(() => {
      this.loadOrganizations()
    })
  },

  computed: {
    organizationWithIndex() {
      return this.organizations.map(
        (organizations, index) => ({
          ...organizations,
          index: index + 1
        })
      )
    }
  },

  methods: {

    backToFacilityList() {
      this.$emit('goToFacilityList', 'Come Back');
    },

    loadOrganizations() {
      FacilitiesService.facilityOrganizations(this.facility).then(
        success => {

          this.organizations = success.data.data

        },
        error => {

          console.log(error)

        }
      );
    }
  }
}
</script>