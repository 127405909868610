<template>
<div>
    <v-row>
        <v-col>
            <gmap-map 
                ref="map"
                :center="center"
                :zoom="zoom"
                style="max-width:100%; height:735px;"
            >
                <GmapMarker
                :position="center" 
                />
            </gmap-map>
        </v-col>
        <v-col>
            <v-card elevation="1">
                <v-card-title class="grey--text text-center">
                    <div class="text-h6 text-center ">
                        {{ this.facility !== null ? this.facility.facility : "No Facility" }}
                    </div>
                    <v-spacer></v-spacer>
                    
                    <v-tooltip left v-if="this.facility == null">
                        <template v-slot:activator="{ on, attrs }">
                            <v-hover v-slot="{ hover }">
                            <v-btn 
                                :class="{ 'red--text' : hover }" 
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="assignOrganizationFacility"
                            >
                                <v-icon color="red">mdi-map-marker-plus</v-icon>
                            </v-btn>
                            </v-hover>
                        </template>
                        <span class="text-caption">Add Location</span>
                    </v-tooltip>

                    <v-tooltip left v-if="this.facility">
                        <template v-slot:activator="{ on, attrs }">
                            <v-hover v-slot="{ hover }">
                            <v-btn 
                                :class="{ 'red--text' : hover }" 
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="assignOrganizationFacility"
                            >
                                <v-icon color="info">mdi-map-marker-plus</v-icon>
                            </v-btn>
                            </v-hover>
                        </template>
                        <span class="text-caption">Edit Location</span>
                    </v-tooltip>
                    
                </v-card-title>

                <v-divider></v-divider>

                <v-data-table
                    :headers="entryPointHeaders"
                    :items="entryPointsWithIndex"
                ></v-data-table>

                
            </v-card>
        </v-col>
    </v-row>

    <v-dialog
        v-model="assignFacilityModal"
        persistent
        max-width="600px"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        scrollable
    >
        <v-card>
            <v-card-title class="grey--text text-h5">
                Assign Facility
            </v-card-title>

            <v-form>
                <v-container>
                    <!-- <v-text-field
                        v-model="organizationModel.name"
                        label="Full Name"
                        :error-messages="apiErrors.name"
                        readonly
                    ></v-text-field> -->

                    <v-text-field
                        v-model="corporation.legal_name"
                        label="Organization"
                        readonly
                        :error-messages="apiErrors.org_id"
                    ></v-text-field>

                    <v-select
                        v-model="organizationModel.facility_id"
                        :items="facilities"
                        item-text="attributes.facility"
                        item-value="id"
                        label="Assign Facility"
                        :error-messages="apiErrors.fcl_id"
                    ></v-select>

                </v-container>
            </v-form>

            <v-card-actions>

            <v-btn
                color="red darken-1"
                text
                @click="closeModal"
            >
                Cancel
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn
                color="green darken-1 white--text"
                @click="saveAssignFacility"
            >
                Save
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    
</div>
</template>

<script>
import EntriesService from '../../services/entries.service';
import Organization from '../../models/Organization';
import facilitiesService from '../../services/facilities.service';
export default {
    name : "Location",
    props : [
        'organization'
    ],

    // data() {
    //     return {
    //         center : { lat: 45.508, lng: -73.587 }
    //     }
    // }

    data() {
        return {
            corporation : this.organization,
            organizationModel : new Organization(),
            //a default center for the map
            center: {lat: -1.262223346227177, lng: 36.80714185645724},
            // center : {},
            zoom : 6,
            map: null,
            infoContent: '',
            infoWindowPos: {
            lat: 0,
            lng: 0
            },
            infoWinOpen: false,
            currentMidx: null,
            //optional: offset infowindow so it visually sits nicely on top of our marker
            infoOptions: {
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
            facilities : [],
            entryPoints : [],
            entryPointHeaders : [
                {
                    text : "#",
                    value : "index"
                },{
                    text : "Passages",
                    value : "entry_point_name"
                }
            ],
            assignFacilityModal : false,
            apiErrors : [],
            selectedFacility : [],
        }
    },

    computed : {

        facility() {
            return this.corporation.facility[0] ?? null;
        },

        entryPointsWithIndex() {
            return this.entryPoints.map(
                (entryPoints, index) => ({
                    ...entryPoints,
                    index: index + 1
                })
            )
        },

        // corporation() {
        //     return this.organization
        // }
    },

    watch : {
        corporation(newCorporation, oldCorporation) {
            if(newCorporation !== oldCorporation) {

                this.setFacilityMarker();
                this.getFacility();
                this.getEntryPoints();

            }
        },
        
    },

    created() {

        this.$nextTick(() => {
            if(this.facility !== null) {
                
                this.setFacilityMarker();
                this.getFacility();
                this.getEntryPoints();

            }
        });
        
    },

    methods : {
        getFacility() 
        {
            
        },

        setFacilityMarker()
        {
            this.center = {
                lat : parseFloat(this.facility.latitude),
                lng : parseFloat(this.facility.longitude)
            }

            this.zoom = 18 

        },

        getEntryPoints()
        {
            EntriesService.facilityEntryPoints(this.facility.id).then(
                entries => {
                    this.entryPoints = entries.data.data
                },
                fail => {
                    console.log(fail)
                }
            );
        },

        assignOrganizationFacility()
        {
            this.getFacilities();
            this.assignFacilityModal = true;
        },

        closeModal()
        {
            this.assignFacilityModal = false;
        },

        getFacilities()
        {
            facilitiesService.index().then(
                facilities => {
                    this.facilities = facilities.data.data
                },
                fail => {
                    console.log(fail)
                }
            );
        },

        saveAssignFacility()
        {

            let organization = {
                org_id : this.corporation.id,
                fcl_id : this.organizationModel.facility_id
            };

            facilitiesService.organizationFacility(organization).then(
                (response) => {

                    if (response.status == 200) {

                        // this.organizationUsers.push(response.data.data);
                        this.$store.dispatch("alert/success", this.corporation.legal_name +" updated");
                        this.loading = false;
                        this.corporation = response.data.data;                       

                    } else {

                        this.$store.dispatch("alert/error", (this.corporation.legal_name ?? "User") +" not updated" + response.message);

                    }

                    this.closeModal();

                },
                (error) => {

                    if (error.response.status == 422) {
                        
                        this.apiErrors = error.response.data.errors

                        console.log(error.response.data.errors)

                        this.$store.dispatch(
                        "alert/error",
                        error.response.data.message
                        );
                        
                        this.loading = false;

                    }

                    this.$store.dispatch("alert/error", error.response.data.message);

                }
            )
        }
    }
}
</script>