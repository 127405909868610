// User model 
export default class Passage{
    constructor(id,entry_point_name, fcl_id, description, passage_type_id, security_level_id){
        this.id = id
        this.entry_point_name = entry_point_name
        this.description = description
        this.passage_type_id = passage_type_id
        this.security_level_id = security_level_id
        this.fcl_id = fcl_id
    }
}