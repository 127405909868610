// User model 
export default class Facility{
    constructor(id, facility, latitude, longitude, entries, org_id, entry_point_name, fcl_id, description, passage_type_id, security_level_id){
        this.id = id
        this.facility = facility
        this.latitude = latitude
        this.longitude = longitude
        this.entries = entries
        this.org_id = org_id
        this.entry_point_name = entry_point_name
        this.description = description
        this.passage_type_id = passage_type_id
        this.security_level_id = security_level_id
        this.fcl_id = fcl_id
    }
}