<template>
  <div>
    <v-card outlined class="mt-n3" tile>
      <Dashboard v-if="selectedFacility" v-bind:facility="selectedFacility"
        @goToFacilityList="backToFacilityList($event)"></Dashboard>

      <v-data-table v-else :headers="headers" :items="facilitiesWithIndex" class="elevation-1"
        loading-text="Loading... Please wait" :search="search">
        <template v-slot:top>
          <v-toolbar flat class="pt-2">

            <div class="mt-5" align="left">
              <v-toolbar-title class="info--text text-uppercase font-weight-bold text--darken-1 justify-center">
                <v-icon class="mr-2 mt-n1" color="info">mdi-bank</v-icon>Facility
              </v-toolbar-title>

              <v-divider class="mx-4" vertical></v-divider>
            </div>

            <v-spacer></v-spacer>

            <div align="right">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="green" dark class="text-capitalize my-auto ml-1" elevation="3"
                    @click="createFacilityModal" v-bind="attrs" v-on="on">
                    <v-icon>mdi-bank-plus</v-icon>
                    <span>Add Facility</span>
                  </v-btn>
                </template>
                <span>Add Facility</span>
              </v-tooltip>
            </div>
          </v-toolbar>

          <v-progress-linear indeterminate color="info" v-if="loading"></v-progress-linear>

          <v-divider></v-divider>

        </template>

        <template v-slot:[`item.allEntries`]="{ item }">
          <v-list dense v-if="(item.relationships.entries).length != 0" class="pt-0">
            <v-list-item two-line v-for="(entry, index) in item.relationships.entries" :key="index">
              <v-list-item-content class="text-left">
                <v-list-item-title>
                  {{ index + 1 }} {{ entry.attributes.passage }} - <span class="grey--text">{{ entry.relationships.type.attributes.type }}</span>
                  <v-icon color="primary" small @click="editFacilityEntry(item, entry)">mdi-pencil-outline</v-icon>
                </v-list-item-title>
                <v-list-item-subtitle v-if="(entry.relationships.security_level) != null" class="info--text">Level : {{ entry.relationships.security_level.attributes.level }}</v-list-item-subtitle>
                <v-spacer></v-spacer>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>

        <template v-slot:[`item.entry_point`]="{ item }">
          <v-btn color="info" class="mx-1" fab x-small dark elevation="0" @click="addEntryPoint(item)">
            <v-icon>mdi-location-enter</v-icon>
          </v-btn>
        </template>

        <template v-slot:[`item.facility`]="{ item }">
          <v-list-item two-line class="pl-0">
            <v-list-item-content>
              <v-list-item-title>{{ item.attributes.facility }}</v-list-item-title>
              <v-list-item-subtitle v-if="(item.relationships.parent_facility) != null" class="info--text">{{ item.relationships.parent_facility?.attributes.facility }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-slot:[`item.facility_manager`]="{ item }">
          <v-list-item two-line class="pl-0">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.relationships.manager?.attributes.legal_name }}
                {{ item.relationships.manager?.attributes.parent_name != null ? ' - ' + (item.relationships.manager?.attributes.parent_name) : null }}
              </v-list-item-title>
              <v-list-item-subtitle v-if="(item.relationships.manager?.relationships.admin) != null" class="info--text">
                {{ item.relationships.manager?.relationships.admin.attributes.name }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-slot:[`item.more`]="{ item }">
          <v-btn color="info" class="mx-1" fab x-small dark elevation="0" @click="selectFacilityOrganizations(item)">
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>

        <template v-slot:[`item.edit`]="{ item }">
          <v-btn color="info" class="mx-1" fab x-small dark elevation="0" @click="editFacility(item)">
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
        </template>

        <template v-slot:[`item.delete`]="{ item }">
          <v-dialog v-model="showDeleteDialog" persistent width="400">
            <v-card>
              <v-card-title class="headline">
                Proceed with caution!
                <span class="float-right">
                  <v-icon class="mdi mdi-alert-circle-outline"></v-icon>
                </span>
              </v-card-title>
              <v-card-text>
                You are about to delete a facility,
                are you sure you want to delete this item?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="showDeleteDialog = false">Cancel</v-btn>
                <v-btn color="error" text @click="deleteFacility(item)">Delete</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn color="error" class="mx-1" fab x-small dark elevation="0" @click="showDeleteDialog = true">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </template>

        <template v-slot:no-data>
          <a class="info--text text--darken-1">
            No Facilities</a>
        </template>
      </v-data-table>
    </v-card>

    <v-row justify="center">
      <v-dialog v-model="showFacilityModal" scrollable max-width="600">
        <v-card>
          <v-card-title class="grey--text text-h5">
            Facility
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text class="mb-0 pb-0">
            <v-form class="pt-7 pb-0">
              <v-autocomplete 
                dense v-model="facility.id_parent" :items="allFacilities" item-value="id"
                item-text="attributes.facility" label="Parent Facility" :error-messages="apiErrors.id_parent" outlined
                @change="childFacility = true"
              >
              </v-autocomplete>

              <v-autocomplete 
                dense v-model="facility.org_id" :items="facility_manager_organizations" item-value="id"
                item-text="attributes.legal_name" label="Facility Manager" :error-messages="apiErrors.org_id"
                outlined
              ></v-autocomplete>

              <v-text-field 
                dense v-model="facility.facility" label="Facility" :error-messages="apiErrors.facility"
                outlined
              ></v-text-field>

              <gmap-map ref="map" :zoom="zoom" :center="center" style="max-width:100%; height:400px;"
                @click="handleMapClick"
                v-if="viewGoogleMap"
              >
                <GmapMarker 
                  :position="centerPosition" :clickable="true" :draggable="true" @drag="handleMarkerDrag"
                  @click="panToMarker" 
                  />
              </gmap-map>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="red darken-1" text @click="closeModal" class="text-capitalize mx-1">
              Cancel
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn v-if="editFacilityModal" color="green darken-1 white--text" @click="saveFacilityUpdates"
              class="text-capitalize mx-1">
              Update
            </v-btn>

            <v-btn v-else color="green darken-1 white--text" @click="saveFacility" class="text-capitalize mx-1">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showEntryPointModal" scrollable max-width="600">
        <v-card>
          <v-card-title class="grey--text text-h5">
            Passage
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text class="mb-0 pb-0">
            <v-form class="pt-7 pb-0">
              <v-autocomplete dense v-model="facility.id_parent" :items="allFacilities" item-value="id"
                item-text="attributes.facility" label="Facility" :error-messages="apiErrors.id_parent" outlined
                @change="childFacility = true"></v-autocomplete>

              <v-row>
                <v-col>
                  <v-text-field dense v-model="passage.entry_point_name" label="Name"
                    :error-messages="apiErrors.entry_point_name" outlined></v-text-field>
                </v-col>
                <v-col>
                  <v-select dense v-model="passage.passage_type_id" :items="passage_types" item-value="id"
                    item-text="attributes.type" label="Type" :error-messages="apiErrors.passage_type_id"
                    outlined></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-select dense v-model="passage.security_level_id" :items="security_levels" item-value="id"
                    item-text="attributes.level" label="Security Level" :error-messages="apiErrors.security_level_id"
                    outlined></v-select>
                </v-col>
                <v-col>
                  <v-select dense v-model="passage.security_level_id" :items="security_levels" item-value="id"
                    item-text="attributes.description" label="Security Level"
                    :error-messages="apiErrors.security_level_id" outlined readonly></v-select>
                </v-col>
              </v-row>

              <v-textarea dense v-model="passage.description" label="Description"
                :error-messages="apiErrors.description" outlined></v-textarea>

            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="red darken-1" text @click="closeModal" class="text-capitalize mx-1">
              Cancel
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn v-if="!editFacilityPassage" color="green darken-1 white--text" @click="saveFacilityEntryPoint"
              class="text-capitalize mx-1">
              Save
            </v-btn>
            <v-btn v-if="editFacilityPassage" color="green darken-1 white--text" @click="updateFacilityEntryPoint"
              class="text-capitalize mx-1">
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import FacilitiesService from '../../services/facilities.service';
import EntriesService from '../../services/entries.service';
import PassageTypeService from '../../services/passage_type.service';
import SecurityLevelService from '../../services/security_level.service';
import Facility from '../../models/facility';
import Passage from '../../models/passage';
import OrganizationService from '../../services/organization.service';
import Dashboard from './Dashboard.vue';

export default {
  components: { Dashboard },
  name: "Facility",
  data() {
    return {
      search: '',
      facilities: [],
      allFacilities: [],
      childFacility: false,
      passage_types: [],
      security_levels: [],
      loading: true,
      showDeleteDialog: false,
      headers: [
        {
          text: "#",
          align: "start",
          filterable: true,
          value: "index",
        }, 
        {
          text: "Facility",
          align: "Start",
          filterable: true,
          value: "facility"
        }, 
        {
          text: "Manager",
          align: "Start",
          filterable: true,
          value: "facility_manager"
        }, 
        {
          text: "Passages",
          align: "center",
          filterable: false,
          value: "allEntries",
        }, 
        {
          text: "New Passage",
          align: "center",
          filterable: false,
          value: "entry_point"
        }, 
        {
          text: "More Details",
          align: "center",
          value: "more",
          sortable: false,
        }, 
        {
          text: "Edit",
          align: "center",
          filterable: false,
          value: "edit"
        }, 
        {
          text: "Delete",
          align: "center",
          filterable: false,
          value: "delete"
        }
      ],
      viewGoogleMap: false,
      showFacilityModal: false,
      editFacilityPassage: false,
      facility: new Facility(),
      passage: new Passage(),
      apiErrors: [],
      editFacilityModal: false,
      user_managed_organizations: [],
      facility_manager_organizations: [],
      showEntryPointModal: false,
      zoom: 6,
      map: null,
      center: { lat: -1.262223346227177, lng: 36.80714185645724 },
      centerPosition: { lat: -1.262223346227177, lng: 36.80714185645724 },
      selectedFacility: null,
    }
  },
  computed: {
    facilitiesWithIndex() {
      return this.facilities.map(
        (items, index) => ({
          ...items,
          index: index + 1,
        })
      )
    },
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
    org() {
      return JSON.parse(localStorage.getItem("org"));
    },
  },
  created() {
    this.$nextTick(() => {
      this.getFacilities();
      this.getUserManagedOrganizations();
      this.getAllFacilities();
      this.getFacilityManagerOrganizations();
      this.geolocate();
    });
  },
  methods: {
    backToFacilityList() {
      this.selectedFacility = null;
    },
    getFacilities() {
      let orgId = this.org?.id;
      FacilitiesService.index(orgId).then(
        (response) => {
          if (response.status == 200) {
            this.facilities = response.data.data;
            console.group('Check Response');
            console.log(response.data.data);
            this.loading = false;
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAllFacilities() {
      FacilitiesService.allFacilities(this.org?.id).then(
        (response) => {
          if (response.status == 200) {
            this.allFacilities = response.data.data;
            this.loading = false;
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    selectFacilityOrganizations(facility) {
      this.selectedFacility = facility
    },
    getFacilityManagerList() {
      FacilitiesService.facilityManagerList(this.org?.id).then(
        (response) => {
          if (response.status == 200) {
            this.facilities = response.data.data;
            this.loading = false;
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getFacilityManagerOrganizations() {
      OrganizationService.facilityManagerOrganizations(this.user.id).then(
        (response) => {
          if (response.status == 200) {
            this.facility_manager_organizations = response.data.data;
            this.loading = false;
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getPassageTypes() {
      PassageTypeService.index().then(
        (response) => {
          if (response.status == 200) {
            this.passage_types = response.data.data;
          }
        },
        (error) => {
          console.log(error)
        }
      );
    },
    getSecurityLevels() {
      SecurityLevelService.index().then(
        (response) => {
          if (response.status == 200) {
            this.security_levels = response.data.data;
          }
        },
        (error) => {
          console.log(error)
        }
      );
    },
    getUserManagedOrganizations() {
      OrganizationService.user_created_organizations().then(
        (response) => {
          this.user_managed_organizations = response.data.data
        },
        (error) => {
          console.log(error)
        }
      );
    },
    createFacilityModal() {
      this.showFacilityModal = true;
      this.getFacilityManagerOrganizations();
    },
    closeModal() {
      this.editFacilityPassage = false;
      this.showFacilityModal = false;
      this.showDeleteDialog = false;
      this.facility = new Facility();
      this.passage = new Passage();
      this.apiErrors = [];

      this.editFacilityModal = false;

      this.showEntryPointModal = false;
      this.geolocate();
    },
    saveFacility() {
      // child facilities use lat and long of the parent facility
      if (this.facility.id_parent) {
        this.facility.latitude = null;
        this.facility.longitude = null;
      } else {
        this.facility.parent_id = null;
      }

      this.facility.org_id = this.org?.id;
      FacilitiesService.store(this.facility).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch("alert/success", "Facility created", response.data.message);
            this.getFacilities();
            this.closeModal();
          } else {
            this.$store.dispatch("alert/error", "Facility not created", response.data.message);
            this.loading = false;
          }
        },
        (error) => {
          if (error.response.status == 422) {
            this.$store.dispatch("alert/error", error.response.data.message);
            this.loading = false;
            this.getFacilities();
          }

          if (error.response.status == 401) {
            this.handleLogout();
          }
          
          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );
    },
    editFacilityEntry(facility, passage) {
      this.editFacilityPassage = true;
      this.passage.id = passage.id;
      this.passage.fcl_id = facility.id;
      this.passage.entry_point_name = passage.attributes.passage;
      this.passage.description = passage.attributes.description;
      let relationships = passage.relationships;

      if (relationships.type != undefined) {
        this.passage.passage_type_id = passage.relationships.type.id;
      }

      if (relationships.security_level != undefined) {
        this.passage.security_level_id = passage.relationships.security_level.id;
      }

      this.addEntryPoint(facility)
    },
    editFacility(facility) {
      this.facility.id = facility.id
      let attributes = facility.attributes
      this.facility.facility = attributes.facility
      this.facility.latitude = attributes.latitude
      this.facility.longitude = attributes.longitude

      this.getFacilityManagerOrganizations();

      if (attributes.latitude && attributes.longitude) {
        this.setFacilityGeoLocation(attributes)
      }

      if (facility.relationships.parent_facility != null) {
        this.facility.id_parent = facility.relationships.parent_facility.id
        this.setFacilityGeoLocation(facility.relationships.parent_facility.attributes)
      }

      let facility_manager_organization = facility.relationships.manager;
      if (facility_manager_organization != null) {
        this.facility.org_id = facility_manager_organization.id;
      }

      this.showFacilityModal = true;
      this.editFacilityModal = true;
    },
    // deleting the data from the database
    deleteFacility(item) {
      FacilitiesService.delete(item).then(
        (response) => {
          if (response.status === 200) {
            let index = this.items.indexOf(item);
            this.items.splice(index, 1)
            this.$store.dispatch("alert/success", "Deleted successfully", response.data.message);
            this.closeModal();
            this.getFacilities();
          } else {
            this.$store.dispatch("alert/error", "Something went wrong", response.data.message);
          }
        },
        (error) => {
          if (error.response.status === 422) {
            this.$store.dispatch("alert/error", error.response.data.message);
          }

          if (error.response.status === 401) {
            this.handleLogout();
          }

          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );
    },
    setFacilityGeoLocation(facility) {
      if (facility.latitude && facility.longitude) {
        this.centerPosition.lat = facility.latitude;
        this.centerPosition.lng = facility.longitude;
        this.zoom = 18
      }
    },
    saveFacilityUpdates() {
      FacilitiesService.update(this.facility).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch("alert/success", "Facility edited successfully", response.data.message);
            this.loading = false;
            this.getFacilities();
            this.closeModal();
          } else {
            this.$store.dispatch("alert/error", response.data.message);
            this.getFacilities();
            this.loading = false;
          }
        },
        (error) => {
          if (error.response.status == 422) {
            this.$store.dispatch("alert/error", error.response.data.message);
            this.loading = false;
            this.getFacilities();
          }

          if (error.response.status == 401) {
            this.handleLogout();
            this.loading = false;
            this.getFacilities();
          }
          
          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );
    },
    addEntryPoint(item) {
      this.facility.fcl_id = item.id
      this.showEntryPointModal = true;
      this.getPassageTypes();
      this.getFacilities();
      this.getSecurityLevels();
    },
    saveFacilityEntryPoint() {
      this.passage.fcl_id = this.facility.id_parent;

      EntriesService.store(this.passage).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.dispatch("alert/success", "Added passage successfully", response.data.message);
            this.loading = false;
            this.getFacilities();
            this.closeModal();
          } else {
            this.$store.dispatch("alert/error", response.data.message);
            this.getFacilities();
            this.loading = false;
          }
        },
        (error) => {
          if (error.response.status == 422) {
            this.$store.dispatch("alert/error", error.response.data.message);
            this.loading = false;
            this.getFacilities();
          }

          if (error.response.status == 401) {
            this.handleLogout();
            this.loading = false;
            this.getFacilities();
          }

          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );
    },
    updateFacilityEntryPoint() {
      EntriesService.update(this.passage).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.dispatch("alert/success", "Update passage successfully", response.data.message);
            this.loading = false;
            this.getFacilities();
            this.closeModal();
          } else {
            this.$store.dispatch("alert/error", response.data.message);
            this.getFacilities();
            this.loading = false;
          }
        },
        (error) => {
          if (error.response.status == 422) {
            this.$store.dispatch("alert/error", error.response.data.message);
            this.loading = false;
            this.getFacilities();
          }

          if (error.response.status == 401) {
            this.handleLogout();
            this.loading = false;
            this.getFacilities();
          }
          
          this.$store.dispatch("alert/error", error.response.data.message);
        }
      );
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.centerPosition.lat = position.coords.latitude;
        this.centerPosition.lng = position.coords.longitude;
        this.center = this.centerPosition;
        this.zoom = 18;
      });
    },
    // sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.centerPosition = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      this.panToMarker();
    },
    // Moves the map view port to marker
    panToMarker() {
      this.facility.latitude = this.centerPosition.lat ?? this.center.lat;
      this.facility.longitude = this.centerPosition.lng ?? this.center.log;

      this.$refs.map.panTo(this.centerPosition);
      this.zoom = 18;
    },
    // Moves the marker to click position on the map
    handleMapClick(e) {
      this.centerPosition = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      this.panToMarker();
    },
  }
} 
</script>