import Axios from './Axios';

class PassageTypeService {

    index() {
        return Axios({ 
            method : 'GET',
            url : 'passage-type', 
        });
    }
    store(type) {
        return Axios({ 
            method : 'POST',
            url : 'passage-type', 
            data : type
        });
    }
    update(type) {
        return Axios({ 
            method : 'PATCH',
            url : 'passage-type/' + type.id, 
            data : type
        });
    }
    delete(type) {
        return Axios({ 
            method : 'DELETE',
            url : 'passage-type/' + type.id
        });
    }
}

export default new PassageTypeService()
