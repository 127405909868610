import Axios from './Axios';

class FacilityBlacklistService {
    facilityBlacklist(facility_id) {
        return Axios({
            method : "GET",
            url : 'facility/'+facility_id+'/blacklist',
        })
    }
    store(blacklist) {
        return Axios({ 
            method : 'POST',
            url : 'facility_blacklist', 
            data : blacklist
        });
    }
    update(blacklist) {
        return Axios({ 
            method : 'PATCH',
            url : 'facility_blacklist/' + blacklist.id, 
            data : blacklist
        });
    }
    delete(blacklist) {
        return Axios({ 
            method : 'DELETE',
            url : 'facility_blacklist/' + blacklist.id
        });
    }
}

export default new FacilityBlacklistService()
