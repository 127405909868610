import Axios from './Axios';
class SecurityLevelService {

    index() {
        return Axios({ 
            method : 'GET',
            url : 'security-level', 
        });
    }
    store(level) {
        return Axios({ 
            method : 'POST',
            url : 'security-level', 
            data : level
        });
    }
    update(level) {
        return Axios({ 
            method : 'PATCH',
            url : 'security-level/' + level.id, 
            data : level
        });
    }
    delete(level) {
        return Axios({ 
            method : 'DELETE',
            url : 'security-level/' + level.id
        });
    }
}

export default new SecurityLevelService()
