<template>
<v-card>
    <v-data-table
        :headers="headers"
        :items="facilityBlacklistWithIndex"
    >
        <template v-slot:top >
            <v-toolbar flat>                
                
                <v-toolbar-title v-if="!showSearch"
                    class="info--text font-weight-bold text--darken-1 justify-center"
                >
                <span>Blacklist</span>
                
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-hover v-slot="{ hover }">
                        <v-btn 
                            :class="{ 'info--text' : hover }" 
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="addUser"
                        >
                            <v-icon color="info">mdi-account-plus</v-icon>
                        </v-btn>
                        </v-hover>
                    </template>
                    <span class="text-caption">Add User</span>
                </v-tooltip>
            </v-toolbar>
        </template>

        <template v-slot:[`item.edit`]="{ item }">
            <v-btn
                color="info"
                class="mx-1"
                fab
                x-small
                dark
                elevation="0"
                @click="editUser(item)"
                :disabled="attributes.removed_on"
            >
                <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
        </template>

        <template v-slot:[`item.delete`]="{ item }">
            <v-chip v-if="item.attributes.removed_on"
                color="success"
            >
                Removed
            </v-chip>
            <v-chip v-else
                class="white--text"
                color="red"
                @click="deleteUser(item)"
            >
                Unblock
            </v-chip>
            
        </template>
        
    </v-data-table>


    <v-row justify="center">
        <v-dialog
            v-model="showUserModal"
            max-width="600"
            scrollable
        >
            <v-card>
                <v-card-title class="grey--text text-h5">
                    Blacklist
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text class="mb-0 pb-0">
                    <v-form class="pt-7 pb-0">
                        <!-- <v-container> -->
                            <v-text-field
                                dense
                                v-model="user.name"
                                label="Full Name"
                                :error-messages="apiErrors.name"
                                outlined
                            ></v-text-field>

                            <div class="pb-2">
                                <template>
                                    <vue-phone-number-input
                                        v-model="phoneNumber"
                                        @update="updatePhoneNumber"
                                        :default-country-code="national_number"
                                        size="md"
                                    ></vue-phone-number-input>
                                    <span class="text-caption pl-4 red--text">{{ apiErrors.phone ? apiErrors.phone[0] : '' }}</span>
                                    <span class="text-caption red--text" v-if="phoneValidationError">{{ phoneValidationError  }}</span>
                                </template>
                            </div>

                            <v-text-field
                                dense
                                v-model="user.id_number"
                                label="ID No"
                                :error-messages="apiErrors.id_number"
                                outlined
                            ></v-text-field>

                            <v-text-field
                                dense
                                v-model="facility.attributes.facility"
                                label="Organization"
                                readonly
                                :error-messages="apiErrors.facility_id"
                                outlined
                            ></v-text-field>

                        <!-- </v-container> -->
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>

                    <v-btn
                        color="red darken-1"
                        text
                        @click="closeModal"
                        class="text-capitalize mx-1"
                    >
                        Cancel
                    </v-btn>

                    <v-spacer></v-spacer>

                    <v-btn v-if="editUserModal"
                        color="green darken-1 white--text"
                        @click="saveUserUpdates"
                        class="text-capitalize mx-1"
                    >
                        Update
                    </v-btn>

                    <v-btn v-else
                        color="green darken-1 white--text"
                        @click="saveUser"
                        class="text-capitalize mx-1"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-row>

</v-card>
</template>

<script>
import Blacklist from '../../models/Blacklist';
import FacilityBlacklistService from '../../services/facility_blacklist.service'
export default {
    name : "Blacklist",
    props : [
        'facility'
    ],

    data () {
        return {
            user : new Blacklist(),
            facilityBlacklist : [],
            headers : [
                {
                    text: "#",
                    align: "start",
                    filterable: true,
                    value: "index",
                },{
                    text: "First Name",
                    align: "start",
                    filterable: true,
                    value: "attributes.firstname",
                },{
                    text: "Last Name",
                    align: "start",
                    filterable: true,
                    value: "attributes.lastname",
                },{
                    text: "Contact",
                    align: "start",
                    filterable: true,
                    value: "attributes.phone",
                },{
                    text: "ID",
                    align: "start",
                    filterable: true,
                    value: "attributes.id_number",
                },{
                    text: "From Date",
                    align: "start",
                    filterable: true,
                    value: "attributes.enforced_on",
                },{
                    text: "To Date",
                    align: "start",
                    filterable: true,
                    value: "attributes.removed_on",
                },{
                    text: "Remove",
                    align: "center",
                    filterable: false,
                    value : "delete"
                }
            ],
            showUserModal : false,
            roles : [],
            apiErrors : [],
            showSearch : null,
            editUserModal : false,
            assignRoleModal : false,
            national_number : "KE",
            phoneNumber : null,
            phoneValidationError : null,
            phoneErrorColor : "blue",
            
        }
    },

    created() {
        this.$nextTick(() => {
            this.getBlacklist();
        });
    },

    computed : {

        facilityBlacklistWithIndex() {
            return this.facilityBlacklist.map(
                (facilityBlacklist, index) => ({
                    ...facilityBlacklist,
                    index: index + 1
                })
            )
        }

    },

    methods : {

        getBlacklist()
        {
            FacilityBlacklistService.facilityBlacklist(this.facility.id).then(
                success => {
                    
                    this.facilityBlacklist = success.data ? success.data.data : this.facilityBlacklist
                    
                },
                error => {
                    console.log(error)
                    this.facilityBlacklist = [];
                }
            )
        },

        saveUser()
        {
            if(this.phoneNumber == null) {
                this.phoneErrorColor = "red"
                this.phoneValidationError = "The phone number is required";
            }
            this.user.facility_id = this.facility.id;

            FacilityBlacklistService.store(this.user).then(
                (response) => {

                    if (response.status === 200) {

                        // this.facilityBlacklist.push(response.data.data);
                        this.$store.dispatch("alert/success", this.user.name +" created");
                        this.loading = false;
                        this.getBlacklist();                        

                    } else {

                        this.$store.dispatch("alert/error", (this.user.name ?? "User") +" not created" + response.message);

                    }

                    this.closeModal();

                },
                (error) => {

                    if (error.response.status === 422) {
                        
                        this.apiErrors = error.response.data.errors

                        console.log(error.response.data.errors)

                        this.$store.dispatch(
                        "alert/error",
                        error.response.data.message
                        );
                        this.loading = false;
                        // this.getData();

                    }

                    this.$store.dispatch("alert/error", error.response.data.message);

                }
            );
        },

        saveUserUpdates()
        {
            this.user.facility_id = this.facility.id;

            FacilityBlacklistService.update(this.user).then(
                (response) => {

                    if (response.status == 200) {

                        // this.facilityBlacklist.push(response.data.data);
                        this.$store.dispatch("alert/success", this.user.name +" updated");
                        this.loading = false;
                        this.getBlacklist();                        

                    } else {

                        this.$store.dispatch("alert/error", (this.user.name ?? "User") +" not updated" + response.message);

                    }

                    this.closeModal();

                },
                (error) => {

                    if (error.response.status == 422) {
                        
                        this.apiErrors = error.response.data.errors

                        console.log(error.response.data.errors)

                        this.$store.dispatch(
                        "alert/error",
                        error.response.data.message
                        );
                        this.loading = false;
                        // this.getData();

                    }

                    this.$store.dispatch("alert/error", error.response.data.message);

                }
            );
        },

        editUser(user) 
        {
            this.user.id = user.id;
            this.user.name = user.attributes.name ?? user.attributes.firstname + " " + user.attributes.lastname;
            this.phoneNumber = user.attributes.phone
            this.user.facility_id = this.facility.id
            this.user.id_number = user.attributes.id_number

            this.editUserModal = true;
            this.showUserModal = true;

        },

        deleteUser(user) 
        {
            this.user.id = user.id
            FacilityBlacklistService.delete(this.user).then(
                (response) => {

                    if (response.status == 200) {

                        this.$store.dispatch("alert/success", this.user.name +" removed");
                        this.loading = false;
                        this.getBlacklist();                        

                    } 


                },
                (error) => {

                    if (error.response.datas != undefined) {
                        
                        this.$store.dispatch("alert/error", error.response.data.message);

                    }

                }
            );
        },

        addUser()
        {
            this.showUserModal = true;
        },

        closeModal() 
        {
            this.user = new Blacklist()
            this.phoneNumber = null;
            this.assignRoleModal = false;
            this.editUserModal = false;
            this.showUserModal = false;
        },

        updatePhoneNumber(data) {
            this.user.phone = data.e164
        },

    }
}
</script>