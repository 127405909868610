// User model 
export default class Blacklist{
    constructor(id, name, phone, id_number, facility_id, organization_id, from_user_id, enforced_on, removed_on){
        this.id = id
        this.name = name
        this.phone = phone
        this.id_number = id_number
        this.facility_id = facility_id
        this.organization_id = organization_id
        this.from_user_id = from_user_id
        this.enforced_on = enforced_on
        this.removed_on = removed_on
    }
}