<template>
    <v-card
        color="mt-3-n"
        flat
        tile
        class="mb-6"
    >        
        <v-toolbar dense class="py-1" elevation="0">

            <v-btn icon @click="backToFacilityList">
                <v-icon class="info--text font-weight-bold">mdi-keyboard-backspace</v-icon>
            </v-btn>

            <v-toolbar-title 
                class="
                    info--text
                    font-weight-bold
                    text--darken-1"
                style="{ max-width : 400px; }"
            >{{ facility.attributes.facility }}</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                    <v-btn 
                        :class="{ 'info--text' : hover }" 
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="loadFacilityOrganizations"
                    >
                        <v-icon color="info">mdi-home-city</v-icon>
                    </v-btn>
                    </v-hover>
                </template>
                <span class="text-caption">Organizations</span>
            </v-tooltip>

            <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                    <v-btn 
                        :class="{ 'info--text' : hover }" 
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="loadFacilityBlacklist"
                    >
                        <v-icon color="info">mdi-account-cancel</v-icon>
                    </v-btn>
                    </v-hover>
                </template>
                <span class="text-caption">Blacklist</span>
            </v-tooltip>

        </v-toolbar>

        <v-divider class="mt-4"></v-divider>

        <FacilityOrganizations v-if="showFacilityOrganizations"
            v-bind:facility="facility"
        ></FacilityOrganizations>

        <Blacklist v-if="showFacilityBlacklist"
            v-bind:facility="facility"
        ></Blacklist>

        <Location v-if="showFacilityLocation"
            v-bind:facility="facility"
        ></Location>

        
        
    </v-card>
</template>

<script>
import FacilityOrganizations from './Organizations.vue';
import Location from './Location.vue';
import Blacklist from './Blacklist.vue';
export default {
  components: { FacilityOrganizations, Blacklist, Location },
    name : "Dashboard",
    props : [
        'facility'
    ],

    data () {
        return {
            showFacilityOrganizations : true,
            showFacilityBlacklist : false,
            showFacilityLocation : false,
        }
    },

    // mounted() {
    //     console.log(this.organization)
    // },

    methods : {

        backToFacilityList()
        {
            this.$emit('goToFacilityList', 'Come Back');
        },

        loadFacilityOrganizations()
        {
            this.showFacilityOrganizations = true
            this.showFacilityBlacklist = false
            this.showFacilityLocation = false
        },

        loadFacilityLocation()
        {
            this.showFacilityLocation = true
            this.showFacilityBlacklist = false
            this.showFacilityOrganizations = false
        },

        loadFacilityBlacklist()
        {
            this.showFacilityLocation = false
            this.showFacilityBlacklist = true
            this.showFacilityOrganizations = false
        }
    }
}
</script>